import request from '/src/utils/request'

export function getconlist(param) {
    return request({
        url: `/api/csConsumePoints/getmylist`,
        method: 'post',
        data: param
    })
}
export function getreclist(param) {
    return request({
        url: `/api/csReceivePoints/getmylist`,
        method: 'post',
        data: param
    })
}
export function pointsCharge(param) {
    return request({
        url: `/api/csReceivePoints/save`,
        method: 'post',
        data: param
    })
}
export function getMypoints() {
    return request({
        url: `/api/csCustomer/getpoints`,
        method: 'post',
    })
}