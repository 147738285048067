<template>
    <div class="integral">
        <div class="count">
            <el-row>
            <el-col :span="10">
                <el-card class="box-card">
                    <el-row>
                        <el-col :span="20"><span><h2>{{$t('message.personcenter.jifenyue')}}</h2></span></el-col>
                        <el-col :span="4"><el-button  type="text" size="large" @click="gotorecharge">{{$t('message.personcenter.recharge')}}</el-button></el-col>
                    </el-row>
                    <span style="font-size:45px;font-weight:bolder;margin-left: 50px">{{ currentPoint }}</span>
                </el-card>
            </el-col>
                <el-col :span="1"></el-col>
            <el-col :span="13">
                <!--<el-card class="box-card">-->
                    <!--<el-row>-->
                        <!--<el-col>-->
                            <!--<el-row>-->
                                <!--<span><h2>请输入充值积分数目：</h2></span>-->
                            <!--</el-row>-->
                            <!--<el-row>-->
                                <!--<el-input style="width: 40%;" type="text"></el-input>-->
                            <!--</el-row>-->
                            <!--<el-row>-->
                                <!--<el-col :span="20">-->
                                    <!--<el-radio-group style="margin-top:10px" v-model="rechargeParams.paymentType" @change="paymentTypeChange">-->
                                        <!--<el-radio  :label="''+ 0">{{$t('message.personcenter.purse')}}</el-radio>-->
                                        <!--<el-radio  :label="''+ 1"><i class="iconfont icon-weixin"></i>{{$t('message.personcenter.wechat')}}</el-radio>-->
                                        <!--<el-radio  :label="''+ 2"><i class="iconfont icon-zhifubao"></i>{{$t('message.personcenter.alipay')}}</el-radio>-->
                                        <!--<el-radio  :label="''+ 3"><i class="el-icon-bank-card"></i>{{$t('message.personcenter.bankcard')}}</el-radio>-->

                                    <!--</el-radio-group>-->
                                <!--</el-col>-->
                                <!--<el-col :span="4">-->
                                    <!--<el-button style="float: right" type="primary" size="small" >提交订单</el-button>-->
                                <!--</el-col>-->

                            <!--</el-row>-->
                        <!--</el-col>-->
                    <!--</el-row>-->

                <!--</el-card>-->
            </el-col>
            </el-row>

        </div>
        <div class="integral_detail">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <el-row>
                            <el-col :span="4"><span>
                              <h2>{{$t('message.personcenter.jifenmingxi')}}</h2>
                            </span></el-col>
                            <el-col :span="20">
                                <span style="margin-left: 200px">{{$t('message.personcenter.jifenjilu')}}：</span>
                                <el-select v-model="value"  @change="select">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="$t(item.label)"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>

                    </div>
                </template>
                <el-table
                        :data="tableData"
                        #default="scope"
                        v-if="this.value == '1'"
                        style="width: 100%"
                        :header-cell-style="{background:'#f6f5ec'}"
                >
                    <el-table-column
                            prop="recordTime"
                            :label="$t('message.personcenter.time')"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            :label="$t('message.personcenter.type')"
                            width="180">
                      <template  #default="scope">
                        <span>{{$t('message.personcenter.consumeinteger')}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                            prop="totalConsumingPoints"
                            :label="$t('message.personcenter.jifenconsumer')">
                    </el-table-column>
                    <el-table-column
                            prop="apiId"
                            :label="$t('message.personcenter.memo')">
                    </el-table-column>
                </el-table>
              <el-table
                  :data="tableData"
                  #default="scope"
                  v-else-if="this.value == '2'"
                  style="width: 100%"
                  :header-cell-style="{background:'#f6f5ec'}"
              >
                <el-table-column
                    prop="recordTime"
                    :label="$t('message.personcenter.time')"
                    width="180">
                </el-table-column>
                <el-table-column
                    :label="$t('message.personcenter.type')"
                    width="180">
                  <template  #default="scope">
                    <span>{{$t('message.personcenter.getinteger')}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="totalEarningPoints"
                    :label="$t('message.personcenter.jifenearn')">
                </el-table-column>
                <el-table-column
                    :label="$t('message.personcenter.memo')">
                  <template  #default="scope">
                    <span v-if="scope.row.chargeType == '1'">{{$t('message.personcenter.alipay')}}</span>
                    <span v-else-if="scope.row.chargeType == '2'">{{$t('message.personcenter.wechat')}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          <div  class="list-footer">
            <el-pagination
                small
                :prev-text="$t('message.previous')"
                :next-text="$t('message.next')"
                layout="slot,prev, pager, next"
                background
                v-model:currentPage="currentPage"
                :total="pagination.total"
                :page-size="pagination.pagesize"
                @current-change="currentChange"
            />
          </div>
        </div>
    </div>
</template>

<script>
import {getconlist,getreclist, getMypoints} from "../../api/integral";

  export default {
    name: "Integral",
    data(){
      return{
        tableData:'',
        currentPoint:'',
        integrallist:{
          customerId:'',
        },
        pagination: {
          current: 0,
          pagesize: 6,
          total: 0
        },
        value:'',
        options:
          [{
            value: '1',
            label: 'message.personcenter.consumerlist'
          }, {
            value: '2',
            label: 'message.personcenter.receiverlist'
          }],
        rechargeParams: {
          "totalAmt": '', //金额
          "paymentType": "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
          "transType": "0" //交易类型[0:充值,1:消费]
        },
        }
    },
    created(){
      this.value = this.options[0].value;
      this.getConsumerlist();
      this.getPoints();
    },
    methods:{
      gotorecharge(){
        this.$router.push("../personalmessage/deposit")
      },
      getEarnList(){
        this.integrallist.customerId=localStorage.getItem('customerId')
        const params = {
          query: this.integrallist,
          pagination: this.pagination
        }
        getreclist(params).then( response =>{
          this.tableData = response.datas
          this.pagination = response.pagination
        })
      },
      getConsumerlist(){
        this.integrallist.customerId=localStorage.getItem('customerId')
        const params = {
          query: this.integrallist,
          pagination: this.pagination
        }
        getconlist(params).then( response =>{
            this.tableData = response.datas
            this.pagination = response.pagination
        })
      },
      getPoints(){
        getMypoints().then(response=>{
          this.currentPoint = response.data
        })
      },
      select(val){
      //  console.log(val)
        if(val == '1'){
          this.getConsumerlist()
        }
        else{
          this.getEarnList()
        }
      },
      currentChange(n) {
        this.pagination.current = n
        if(this.value == '1'){
          this.getConsumerlist()
        }
        else{
          this.getEarnList()
        }
      },
    }
  };
</script>

<style lang="scss">
.integral{
    width:100%;
    height: 100%;
    .count{
        width: 100%;
        margin-bottom: 20px;
        .el-input--suffix .el-input__inner{
            height: 10px;
        }
    }
}
.list-footer{
  width:100%;
  display: flex;
  justify-content: center;
  #tiao{
    margin-right: 30px;
  }
}

</style>